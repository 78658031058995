import { signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/config.firebase";
import { GoogleAuthProvider } from "firebase/auth";
import { useEffect } from "react";

function Login() {
    const provider = new GoogleAuthProvider();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // ...
            } else {
                // User is signed out
                // ...
            }
        });
    }, [])
    const login = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    return (
        <>
            <button onClick={login}>Login with Google</button>
        </>
    )
}

export default Login
