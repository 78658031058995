import * as React from 'react';
import Navbar from "../components/navbar";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useStateValue } from "../contextApi/stateContent";
import { Button, Modal } from 'antd';

function QnAPage() {
    const { state } = useStateValue()
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Helmet>
                <title>K4-Karachieez</title>
            </Helmet>
            <Navbar />
            <div className='max-w-screen-xl m-auto px-4 xl:p-0'>
                <h1 className='text-center mb-2 border-b-2 border-blue-400 w-fit m-auto px-2 py-1'>{state.lang === "English" ? `Questions And Answers` : `سوالات و جوابات`}</h1>
                <p className='text-center'>
                    {
                        state.lang === "English" ? `Welcome to our Q&A section! Here, you can find answers to your questions about goat farming. Whether you're a new goat farmer or a seasoned veteran, we're here to help you learn and grow.` : `ہمارے سوال و جواب کے سیکشن میں خوش آمدید! یہاں، آپ بکریوں کی فارمنگ کے بارے میں اپنے سوالات کے جوابات حاصل کر سکتے ہیں۔ چاہے آپ بکری کے نئے فارمرز ہوں یا ایک تجربہ کار تجربہ کار، ہم آپ کو سیکھنے اور بڑھنے میں مدد کرنے کے لیے حاضر ہیں۔`
                    }
                </p>
            </div>
            {/* <div>
                <Button type="primary" onClick={() => setOpen(true)}>
                    Vertically centered modal dialog
                </Button>
                <Modal
                    title="Vertically centered modal dialog"
                    centered
                    open={open}
                    onOk={() => setOpen(false)}
                    onCancel={() => setOpen(false)}
                >
                    <p>some contents...</p>
                    <p>some contents...</p>
                    <p>some contents...</p>
                </Modal>
            </div> */}
        </>
    )
}

export default QnAPage
