import Navbar from "../components/navbar";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { useStateValue } from "../contextApi/stateContent";

function HomePage() {
    const { state } = useStateValue()
    // const updateState = useStateUpdate()
    useEffect(() => {
        console.log(state)
    }, [state])
    return (
        <>
            <Helmet>

            </Helmet>
            <Navbar />
            <div className="max-w-screen-xl px-4 xl:px-0 m-auto">
                <h1 className="text-center font-semibold border-b-2 border-blue-500 w-fit m-auto px-2 mt-1">
                    ٗ{
                        state?.lang == "English" ? 'My aim' : "میرا مقصد"
                    }
                </h1>
                <p className="text-center mt-2 tracking-wider">
                    {
                        state?.lang !== 'English' ? `میرا مقصد سنتِ نبوی کو آگے بڑھانا اور آپلوگوں کو گوٹ فارمنگ سے متعلق اپنی مکمل معلومات پہنچا ہے۔۔۔
                            اسی سوچ کو مدنظر رکھتے ہوئے آپ سب کی آسانی کیلئے یہاں انشاللٌٰہ آپکو مکمل سہولت میسر ہوگی، میرا اپنا ذاتی تجربہ ہے کے اس شوق میں بے انتہا برکت ہے۔۔۔
                            حدیث شریف میں ہے کہ:
                            جس گھر میں بکری ہو وہاں آفات اور فاقے داخل نہیں ہو سکتے۔۔۔
                            یہ ایک بہت گہرا سمندر ہے اور سیکھنے کیلئے دل چسپی اور توجہ طلب کام ہے تو اگر آپ اس فیلڈ میں آنا چاہتے ہیں تو سب سے پہلے اپنے دل و دماغ کو تیار کریں جو کچھ آپ اس پیج پر دیکھیں گے اسکو اچھی طرح سے سمجھیں تا کہ کسی بھی طرح سے بے زبان جانور کے ساتھ ذیادتی نہ ہو۔۔۔۔
                            امید کرتا ہوں کہ آپ میری باتوں کو توجہ کیساتھ پڑھیں گے اور ان کو آگے بڑھائیں گے۔۔۔ یاد رکھیں علم وہ دولت ہے جسے جتنا بانٹو اتنی بڑھتی ہے۔۔۔۔` : `My aim is to advance the Sunnah of the Prophet and to give you my complete knowledge about goat farming. Keeping this thought in mind, you will be fully facilitated here for the convenience of all of you. I have my own personal experience and I am extremely blessed in this hobby. It is in Hadith Sharif that: Disasters and famines cannot enter the house where there is a goat. It is a very deep sea and it is a hard and attentive work to learn. First, prepare your heart and mind to understand what you will see here so that you do not abuse your animal in any way. I hope you will read my words carefully and understand them. Let's move forward. Remember that knowledge is the wealth that increases the more it is shared.`
                    }
                </p>

                <p className="text-center-font-medium text-center mt-2">
                    میں اکیلا ھی چلا تھا جانب منزل مگر <br />
                    لوگ آتے گۓ اور کارواں بنتا گیا
                </p>

            </div>
        </>
    )
}

export default HomePage