// StateContext.js
import React, { createContext, useState, useContext } from 'react';

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [state, setState] = useState({
    lang: 'اردو',
    user: null
  });

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
export const useStateUpdate = () => {
  const { setState } = useContext(StateContext);

  const updateState = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  return updateState;
};
