import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages";
import MarketPlace from "../pages/marketplace";
import QnAPage from "../pages/QnA";
import { GoogleAuthProvider } from "firebase/auth";
import ComingSoon from "../components/coming soon";
import ContactPage from "../pages/contact";

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />}></Route>
                {/* <Route path="/marketplace" element={<MarketPlace />}></Route> */}
                {/* <Route path="/QnA" element={<QnAPage />}></Route> */}
                <Route path="/contact" element={<ContactPage />}></Route>
                <Route path="*" element={<ComingSoon />}></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter