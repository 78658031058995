import '../App.css'
// 'use client';
import { Dropdown, Navbar } from 'flowbite-react';
import { Avatar } from 'flowbite-react';
import { useState, useEffect } from 'react';
import { useStateUpdate } from '../contextApi/stateContent';
import { useStateValue } from '../contextApi/stateContent';
import Login from './login';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/config.firebase';
import logo from '../images/logo.jpg'

export default function NavbarWithDropdown() {
    const { state } = useStateValue()
    const updateState = useStateUpdate()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                updateState({
                    user: true
                })
                // ...
            } else {
                // User is signed out
                // ...
                updateState({
                    user: null
                })
            }
        });
    }, [])

    return (
        <div className=''>
            <Navbar
                fluid
                className='border-b-2 max-w-screen-xl m-auto'
                onClick={() => console.log(state)}
            >
                <Navbar.Brand>
                    <span>
                        <img src={logo} alt="" className='w-24 h-24'/>
                    </span>
                    <span className="self-center whitespace-nowrap text-sm font-semibold dark:text-white uppercase">
                        K4 KarachiEEz
                    </span>
                </Navbar.Brand>
                <div className="flex md:order-2">
                    {/* {state?.user ? <Dropdown
                        inline
                        label={<Avatar alt="User settings" img="https://flowbite.com/docs/images/people/profile-picture-5.jpg" rounded />}
                    >
                        <Dropdown.Header>
                            <span className="block text-sm">
                                Bonnie Green
                            </span>
                            <span className="block truncate text-sm font-medium">
                                name@flowbite.com
                            </span>
                        </Dropdown.Header>
                        <Dropdown.Item>
                            Dashboard
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Settings
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Earnings
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item>
                            Sign out
                        </Dropdown.Item>
                    </Dropdown> : <Login />} */}
                    <select name="" className='text-black' id="" onChange={(e) => updateState({
                        lang: e.target.value
                    })}>
                        Select Language
                        <option value="اردو" className='text-black'>اردو</option>
                        <option value="English" className='text-black'>English</option>
                    </select>
                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse>
                    <Navbar.Link
                        active
                        href="/"
                    >
                        <p>
                            {state.lang == "English" ? `Home` : `گھر`}
                        </p>
                    </Navbar.Link>
                    <Navbar.Link href="/about">
                        {state.lang == "English" ? `About` : `ہمارے بارے میں`}
                    </Navbar.Link>
                    <Navbar.Link href="/services">
                        {state.lang == "English" ? `Services` : `خدمات`}
                    </Navbar.Link>
                    <Navbar.Link href="/QnA">
                        {state.lang == "English" ? `Q&A` : `سوالات و جوابات`}
                    </Navbar.Link>  
                    <Navbar.Link href="/contact">
                        {state?.lang == "English" ? `Contact` : `رابطہ کریں`}
                    </Navbar.Link>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}


